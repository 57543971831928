import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { useUser } from '@hooks/user'
import { Dropdown } from './Dropdown'
import styles from './Dropdown/Dropdown.module.css'

const UserDropdown = dynamic(
  async () => await import('./UserDropdown').then(mod => mod.UserDropdown),
  { ssr: false }
)

const solutionsLinks = [
  {
    label: 'Home Buyers',
    href: '/repair-pricing/home-buyers'
  },
  {
    label: 'Home Sellers',
    href: '/repair-pricing/home-sellers'
  },
  {
    label: 'Real Estate Agents',
    href: '/repair-pricing/realtors'
  },
  {
    label: 'Home Inspectors',
    href: '/repair-pricing/home-inspectors'
  },
  {
    label: 'Property Investors',
    href: '/repair-pricing/property-investors'
  }
]

const featuresLinks = [
  {
    label: 'Home Repair Estimate',
    href: '/features/domoreport-repair-estimates'
  },
  {
    label: 'Documents',
    href: '/features/upload-property-documents'
  }
  // {
  //   label: 'Explore',
  //   href: '/features/explore-homeowner-resources'
  // }
]

const aboutLinks = [
  {
    label: 'About Us',
    href: '/about'
  },
  {
    label: 'Blog',
    href: '/blog'
  },
  {
    label: 'Newsroom',
    href: '/newsroom'
  }
]

const discoverLinks = [
  {
    label: 'About Us',
    href: '/about'
  },
  {
    label: 'Blog',
    href: '/blog'
  },
  {
    label: 'Agent Toolkit',
    href: '/home-agent-toolkit'
  },
  {
    label: 'Newsroom',
    href: '/newsroom'
  }
]

const helpLinks = [
  {
    label: 'FAQ',
    href: 'https://intercom.help/majordomo/en/'
  },
  {
    label: 'Contact Us',
    href: '/contact-us'
  }
]

const mobileNavVariants = {
  hidden: {
    opacity: 0,
    y: -20,
    x: 20
  },
  visible: {
    opacity: 1,
    y: 0,
    x: 0
  }
}

export function Header() {
  const { isLoggedIn, logout } = useUser()
  const menuRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (!menuRef.current?.contains(event.target as any)) {
        setShowMenu(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  return (
    <header className="p-8 md:py-12 md:px-4 container mx-auto flex justify-between items-center">
      <Link href="/">
        <a>
          <img
            src="/images/thumbtack/wordmark_dark.png"
            className="w-48"
            alt="majordomo logo"
          />
        </a>
      </Link>
      <nav className="hidden lg:flex items-center space-x-12">
        {/* <Dropdown label="Solutions" links={solutionsLinks} /> */}
        {/* <Dropdown label="Features" links={featuresLinks} /> */}
        {/* <Dropdown label="About" links={aboutLinks} /> */}
        {/* <Dropdown label="Help" links={helpLinks} /> */}
        <UserDropdown />
      </nav>
      <button
        onClick={() => setShowMenu(true)}
        className="lg:hidden rounded-lg p-2 text-gray-700 transition-colors bg-gray-100 hover:bg-gray-200"
        aria-label="menu"
      >
        <MenuIcon width={24} height={24} />
      </button>
      <AnimatePresence>
        {showMenu && (
          <motion.nav
            variants={mobileNavVariants}
            ref={menuRef}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="lg:hidden absolute top-4 left-4 right-4 p-4 shadow-xl rounded-lg text-gray-400 bg-gray-800 z-10"
          >
            <button
              onClick={() => setShowMenu(false)}
              className="absolute p-2 right-4 top-4"
            >
              <XIcon width={24} height={24} />
            </button>
            {/* <div className="md:flex">
              <div className="w-full md:w-1/3">
                <h2 className="text-lg font-bold p-2 text-gray-200">
                  Solutions
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-1 mb-8">
                  {solutionsLinks.map(link => (
                    <Link href={link.href}>
                      <a className="px-2 py-1 rounded hover:bg-gray-700 transition-colors">
                        {link.label}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="w-full md:w-1/3">
                <h2 className="text-lg font-bold p-2 text-gray-200">
                  Features
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-1 mb-8">
                  {featuresLinks.map(link => (
                    <Link href={link.href}>
                      <a className="px-2 py-1 rounded hover:bg-gray-700 transition-colors">
                        {link.label}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="w-full md:w-1/3">
                <h2 className="text-lg font-bold p-2 text-gray-200">
                  Discover
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-1 mb-8">
                  {discoverLinks.map(link => (
                    <Link href={link.href}>
                      <a className="px-2 py-1 rounded hover:bg-gray-700 transition-colors">
                        {link.label}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 md:w-1/3 md:grid-cols-1">
                <div>
                  <h2 className="text-lg font-bold p-2 text-gray-200">
                    About
                  </h2>
                  <div className="grid grid-cols-1 mb-8 md:mb-0">
                    {aboutLinks.map(link => (
                      <Link href={link.href}>
                        <a className="px-2 py-1 rounded hover:bg-gray-700 transition-colors">
                          {link.label}
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
                <div>
                  <h2 className="text-lg font-bold p-2 text-gray-200">Help</h2>
                  <div className="grid grid-cols-1 mb-8">
                    {helpLinks.map(link => (
                      <Link href={link.href}>
                        <a className="px-2 py-1 rounded hover:bg-gray-700 transition-colors">
                          {link.label}
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}
            {isLoggedIn ? (
              <>
                <h2 className="text-lg font-bold p-2 text-gray-200">
                  My Account
                </h2>
                <div className="grid grid-cols-1 pb-2">
                  <a
                    href="/repair-estimate"
                    className="px-2 py-1 rounded hover:bg-gray-700 transition-colors"
                  >
                    My Properties
                  </a>
                  <Link href="/settings">
                    <a className="px-2 py-1 rounded hover:bg-gray-700 transition-colors">
                      Settings
                    </a>
                  </Link>
                  <a
                    className="px-2 py-1 rounded hover:bg-gray-700 transition-colors"
                    onClick={() => logout.mutate()}
                  >
                    Log Out
                  </a>
                </div>
              </>
            ) : (
              <div className="flex space-x-4">
                <Link href="/signup">
                  <a className="block p-4 w-1/2 rounded bg-gray-700 text-center font-bold hover:bg-gray-600 transition-colors">
                    Sign Up
                  </a>
                </Link>
                <Link href="/login">
                  <a className="block p-4 w-1/2 rounded bg-gray-200 text-gray-700 font-bold text-center hover:bg-gray-100 transition-colors">
                    Log In
                  </a>
                </Link>
              </div>
            )}
          </motion.nav>
        )}
      </AnimatePresence>
    </header>
  )
}
