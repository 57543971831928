import Link from 'next/link'
import { NextSeo } from 'next-seo'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer'
import { Button } from '@components/UI/Button'
import FeaturesList from '@components/DomoreportFeaturesList'
import DomoreportBenefits from '@components/DomoreportBenefits'
import { isProduction } from './_app'
import ReactGA from 'react-ga4'

export default function Index() {
  function trackCtaClick() {
    isProduction &&
      ReactGA.event({
        category: 'User Actions',
        action: 'Homepage Checkout CTA'
      })
  }

  return (
    <>
      <NextSeo
        title="Home Repair Estimates"
        description="Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours."
        openGraph={{
          title: 'Home Repair Estimates',
          description:
            'Estimate home inspection and maintenance repair costs. Upload any inspection report. Get accurate local pricing for all repair items in 24 hours.',
          images: [
            {
              url: 'https://majordomo.com/images/offers/mockup.png',
              width: 4014,
              height: 2360,
              alt: 'Computer screen shot of Thumbtacks’s Home Repair Estimate'
            }
          ]
        }}
      />
      <Header />

      <main className="pt-8 px-0 container mx-auto">
        <div>
          <h2 className="text-brand text-center mx-auto lg:text-6xl sm:text-5xl text-4xl font-bold tracking-tight md:max-w-3xl md:px-8">
            Get repair estimates,
          </h2>
          <h2 className="md:mb-16 mb-8 text-brand text-center mx-auto lg:text-6xl sm:text-5xl text-4xl font-bold tracking-tight md:max-w-3xl md:px-8">
            then book a pro directly.
          </h2>
        </div>

        <div className="flex md:flex-row flex-col justify-between items-center lg:pb-36 pb-12">
          <div className="flex flex-col md:items-start items-center justify-center md:w-1/2 w-full xl:p-20 md:p-8 px-8 md:order-first order-last">
            <h1 className="font-bold font-heading lg:text-6xl md:text-5xl text-4xl lg:max-w-sm max-w-sm md:mb-8 mb-4">
              From repair estimates to repairs done.
            </h1>

            <div className="w-full lg:max-w-md max-w-sm p-0 pr-2 space-y-4">
              <p>
                Negotiate the best deal during your home sale with zip code
                specific repair estimates from your inspection, prepared by
                industry experts. You’ll get your repair estimate within 24
                hours and be ready to negotiate the best home sale price based
                on your inspection. Then you can book a Thumbtack pro right from
                your repair estimate report.
              </p>
            </div>

            <Link href="/repair-estimate/checkout">
              <a className="mt-8">
                <Button
                  variant="primary"
                  clipSide="right"
                  onClick={trackCtaClick}
                >
                  Get your repair estimates
                </Button>
              </a>
            </Link>
          </div>

          <div className="relative sm:w-1/2 w-3/4">
            <video
              className="md:order-last order-first md:mb-0 mb-8 md:max-w-none max-w-xs mx-auto"
              width="100%"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="/videos/home-hero.mp4" type="video/mp4" />
            </video>

            <img
              src="/images/offers/mockup.png"
              className="absolute lg:inline hidden -bottom-16 2xl:-left-72 xl:-left-40 lg:-left-20"
              width="80%"
              alt="Computer screen shot of Thumbtacks's Home Repair Estimate"
            />
          </div>
        </div>

        <div>
          <div className="lg:mb-36 mb-12 lg:pt-12 pt-0 px-8">
            <h2 className="text-4xl text-black-900 font-bold text-center mt-8 mb-4">
              How It Works
            </h2>

            <p className="font-light max-w-lg text-lg xl:mb-24 mb-12 text-center mx-auto">
              Follow a few simple steps to get access to your repair estimates,
              called a Home Repair Estimate, within 24 hours.
            </p>

            <div>
              <div className="flex flex-col w-full xl:mb-24 mb-12 xl:ml-8">
                {/* <div className="flex items-center justify-center xl:w-1/2 w-full lg:mt-0 mt-12 xl:order-first order-last">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/ILXF33MJdpc?hl=en_US&amp;rel=0&amp;autohide=1&amp;autoplay=0"
                    frameBorder="0"
                    className="rounded shadow-sm"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div> */}

                <div className="mx-auto w-full 2xl:max-w-sm xl:max-w-lg lg:max-w-md max-w-sm xl:mb-0 mb-20">
                  <div className="relative 2xl:ml-0 ml-20 mb-20">
                    <div className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32 bg-no-repeat bg-center tt-dashed-line-background">
                      <div className="bg-blue-100 flex items-center justify-center font-bold text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        1
                      </div>
                    </div>

                    <h4 className="lg:text-2xl text-lg font-bold">
                      Upload your home inspection
                    </h4>
                  </div>

                  <div className="relative 2xl:ml-0 ml-20 mb-8 h-20">
                    <div className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32 bg-no-repeat bg-center tt-dashed-line-background">
                      <div className="bg-blue-100 flex items-center justify-center font-bold text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        2
                      </div>
                    </div>

                    <h4 className="lg:text-2xl text-lg font-bold absolute -top-6">
                      Access your Home Repair Estimate with repair estimates
                      within 24 hours
                    </h4>
                  </div>

                  <div className="relative 2xl:ml-0 ml-20">
                    <div className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32 bg-no-repeat bg-center">
                      <div className="bg-blue-100 flex items-center justify-center font-bold text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        3
                      </div>
                    </div>

                    <h4 className="lg:text-2xl text-lg font-bold">
                      Book Thumbtack pros directly from your report
                    </h4>
                  </div>

                  {/* <div className="relative lg:h-8 h-0 2xl:ml-0 ml-20">
                    <div className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32">
                      <div className="bg-blue-100 flex items-center justify-center font-bold text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        4
                      </div>
                    </div>
                    <h4 className="lg:text-2xl text-lg font-bold max-w-sm">
                      Create a request list of repairs and credits for
                      negotiations
                    </h4>
                  </div> */}
                </div>
              </div>

              <p className="max-w-lg font-light text-center leading-8 mb-12 mx-auto font-heading">
                You can access your Home Repair Estimate securely anytime on
                desktop or mobile, and share access to collaborate with your
                family or your real estate agent.
              </p>

              <div className="flex justify-center max-w-sm mx-auto">
                <Link href="/repair-estimate/checkout">
                  <a>
                    <Button clipSide="right" onClick={trackCtaClick}>
                      Get your repair estimates
                    </Button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <DomoreportBenefits type="client" />

        <FeaturesList
          title="All Included Features"
          url="/repair-estimate/checkout"
          buttonText="Get your repair estimates"
          trackCtaClick={trackCtaClick}
        />

        {/* <div className="lg:mb-36 mb-12">
          <h4 className="text-center md:text-2xl text-lg font-bold tracking-tight md:px-0 px-8 lg:pb-16 pb-8">
            Majordomo helps each party during a transaction
          </h4>

          <div className="md:space-y-16 space-y-8 px-8">
            <div className="flex md:flex-row flex-col space-evenly">
              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="people hugging"
                  src="/images/product-pages/buyers-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-bold tracking-tight mb-2">
                    Home Buyers
                  </h4>
                  <p>
                    Focus on the big picture with condition and repair estimates
                    organized in one easy-to-read report.
                  </p>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="woman working in coffee shop"
                  src="/images/product-pages/re-agent-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-bold tracking-tight mb-2">
                    Real Estate Agents
                  </h4>
                  <p>
                    Save time after the inspection, negotiate smarter with
                    sellers, and close more transactions.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex md:flex-row flex-col space-evenly">
              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="people holding sign"
                  src="/images/product-pages/sellers-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-bold tracking-tight mb-2">
                    Home Sellers
                  </h4>
                  <p>
                    Set your home apart by fixing the biggest issues and earn
                    the trust of prospective home buyers.
                  </p>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="man inspecting house"
                  src="/images/product-pages/inspectors-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-bold tracking-tight mb-2">
                    Home Inspectors
                  </h4>
                  <p>
                    The Home Repair Estimate finally gives home inspectors an
                    answer to the No. 1 question they get after an inspection.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="bg-black lg:mb-36 mb-12 py-24 px-8">
          <h1 className="text-5xl text-white font-bold text-center mb-12">
            Frequently Asked Questions
          </h1>

          <div className="flex lg:flex-row flex-col text-white mb-12">
            <div className="lg:w-1/2 w-full space-y-12">
              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-bold font-heading mt-8 mb-4">
                  "Can I try the Home Repair Estimate before I
                  purchase one?"
                </h4>
                <p className="font-light tracking-wider">
                  Yes. We created a couple of sample reports so you can see
                  exactly what you're ordering. You can check out a{' '}
                  <a
                    href="/demo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand hover:underline"
                  >
                    sample report
                  </a>
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-bold font-heading mt-8 mb-4">
                  "What are my payment options?"
                </h4>
                <p className="font-light tracking-wider">
                  We use Stripe to securely process transactions. So we accept
                  all major credit cards to pay for the Home Repair Estimate
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-bold font-heading mt-8 mb-4">
                  “When will I know my Home Repair Estimate is
                  ready?”
                </h4>
                <p className="font-light tracking-wider">
                  We know your purchase is time sensitive so we’ll send you an
                  email and text message when it’s ready so you can look at it
                  right away.
                </p>
              </div>
            </div>

            <div className="lg:w-1/2 w-full space-y-12">
              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-bold font-heading mt-8 mb-4">
                  “Can I share my Home Repair Estimate?”
                </h4>
                <p className="font-light tracking-wider">
                  Absolutely. You can share by adding people during the ordering
                  process and by clicking the share button at the top of the
                  Home Repair Estimate.{' '}
                  <a
                    href="https://intercom.help/majordomo/en/articles/3216497-how-do-i-share-my-domoreport"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand hover:underline"
                  >
                    Learn more
                  </a>
                  . We also make it easy to{' '}
                  <a
                    href="https://intercom.help/majordomo/en/articles/3301636-how-do-i-read-and-use-the-request-list"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand hover:underline"
                  >
                    print and share
                  </a>{' '}
                  the request list.
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-bold font-heading mt-8 mb-4">
                  "Do you create Domoreports for multi-family properties?"
                </h4>
                <p className="font-light tracking-wider">
                  Yes, but our pricing and timing are different based on the
                  number of units. If you have a multi-family inspection,{' '}
                  <Link href="/contact-us">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-brand hover:underline"
                    >
                      contact us
                    </a>
                  </Link>{' '}
                  and we'll discuss the cost and timing for your particular
                  needs.
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-bold font-heading mt-8 mb-4">
                  "I'm not very technical. Will I still be able to use the
                  Home Repair Estimate?"
                </h4>
                <p className="font-light tracking-wider">
                  There's a comprehensive library of{' '}
                  <a
                    href="https://intercom.help/majordomo/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand hover:underline"
                  >
                    frequently asked questions
                  </a>{' '}
                  that will guide you through the Home Repair Estimate. There's also a{' '}
                  <a
                    href="https://youtu.be/2zEgrYvo5jE"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand hover:underline"
                  >
                    video tour
                  </a>{' '}
                  showing how everything works. And if you get stuck at any
                  point, our team will be happy to assist you at any time.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <p className="font-light text-center text-white leading-8 tracking-wider">
              More questions? Let us help.
              <br />
              <Link href="/contact-us">
                <a className="text-brand hover:underline">Contact us</a>
              </Link>{' '}
              or check out our full{' '}
              <a
                href="https://intercom.help/majordomo/en/"
                className="text-brand hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
              .
            </p>
          </div>
        </div> */}

        {/* <div className="flex items-center justify-center w-full lg:mb-36 mb-12">
          <Link href="/repair-estimate/checkout">
            <a>
              <Button variant="primary" clipSide="right">
                Get repair estimates{' '}
                <ArrowRightIcon width={18} height={18} className="ml-2" />
              </Button>
            </a>
          </Link>
        </div> */}
      </main>

      <Footer />
    </>
  )
}
